#map {
  width: 100%;
  position: absolute;
  top: 3.4rem;
  bottom: 0;
}

#parcelCat {
  display: none;
}

#panelTrasnform, #panelFile {
  width: 390px;
}

#panelOutput {
  width: 500px;
}

#tableOutput {
  display: block;
  overflow-y: scroll;
}

.xlarge {
  font-size: xx-large;
}
/*# sourceMappingURL=index.87e6ebd4.css.map */
