#map { 
  position: absolute;
  top: 3.4rem;
  bottom: 0;
  width: 100%;
}
#parcelCat {
  display: none;
}
#panelTrasnform, #panelFile {
  width: 390px;
}
#panelOutput {
  width: 500px;
}
#tableOutput {
  display: block;
  overflow-y: scroll;
}
.xlarge {
  font-size: xx-large;
}